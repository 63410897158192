import React, { useEffect, useState } from 'react';
import styled from 'styled-components';


import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../state/actions';
import { database } from '../../services';

import Redirections from '../../constants/routes';
import * as evaluate from '../../validators';

// Assets

import defaults from '../../constants/defaults';

import * as assets from '../../assets';

import { elements, inputs, materials, icons } from '../../components';

import useInput from '../../hooks/useInput';

const Front = () => {

    const user = useSelector(state => state.auth);

    // Make sure you log in if already in
    useEffect(() => {
        setLoggedIn(!!user.authenticated);
    }, [user])

    const [loggedIn, setLoggedIn] = useState(false);
    const [spinnerSwitch, setSpinnerSwitch] = useState(false);
    const [subSpinnerSwitch, setSubSpinnerSwitch] = useState(false);
     
    const [showModal, setShowModal] = useState(false);    
    const [showRSVPform, setShowRSVPform] = useState(false);

    const { value:password, bind:bindPassword, reset:resetPassword } = useInput('');


    const { value:passcode, bind:bindPasscode, reset:resetPasscode } = useInput('');
    const { value:email, bind:bindEmail, reset:resetEmail } = useInput('');
    const { value:mobile, bind:bindMobile, reset:resetMobile } = useInput('');
    const { value:arrivingFrom, bind:bindArrivingFrom, reset:resetArrivingFrom } = useInput('Australia');
   

    const [arrivingAtAirport, setArrivingAtAirport] = useState(false);
    const [accomodationRequired, setAccomodationRequired] = useState(true);
    const [willArriveByFirstEvent, setWillArriveByFirstEvent] = useState(true);
    const [willStayForWholeWedding, setWillStayForWholeWedding] = useState(true);
    const [wouldLikeToStayBeyondWedding, setWouldLikeToStayBeyondWedding] = useState(true);
    
    const [attending, setAttending] = useState(true);

    const { value:alternativeAccomodationName, bind:bindAlternativeAccomodationName, reset:resetAlternativeAccomodationName } = useInput('');
    const { value:accomodationDetails, bind:bindAccomodationDetails, reset:resetAccomodationDetails } = useInput('');

    const { value:arrivalDate, bind:bindArrivalDate, reset:resetArrivalDate } = useInput('');
    const { value:flightNo, bind:bindFlightNo, reset:resetFlightNo } = useInput('');



    const [passwordHelper, setPasswordHelper] = useState([]);
    const [emailHelper, setEmailHelper] = useState([]);
    const [arrivingFromHelper, setArrivingFromHelper] = useState([]);
    const [mobileHelper, setMobileHelper] = useState([]);
    const [passcodeHelper, setPasscodeHelper] = useState([]);
    const [guestListHelper, setGuestListHelper] = useState([]);
    const [arrivalDateHelper, setArrivalDateHelper] = useState([]);
    const [flightNoHelper, setFlightNoHelper] = useState([]);
    const [accomodationDetailsHelper, setAccomodationDetailsHelper] = useState([]);

    const [accomodationRequiredHelper, setAccomodationRequiredHelper] = useState([]);
    const [arrivingAtAirportHelper, setArrivingAtAirportHelper] = useState([]);
    const [alternativeAccomodationNameHelper, setAlternativeAccomodationNameHelper] = useState([]);
    const [attendingHelper, setAttendingHelper] = useState([]);

    const [generalHelper, setGeneralHelper] = useState([])

    const [infoSectionOutline, setInfoSectionOutline] = useState(null);
    const [eventScheduleOutline, setEventScheduleOutline] = useState(null);
    const [rsvpDetails, setRSVPDetails] = useState(null);

    const [guestList, setGuestList] = useState([
        { name: '', dietaryRequirements: '' }
    ])


    const handleOnChange = (index, event) => {
        let data = [...guestList];
        data[index][event.target.name] = event.target.value;

        setGuestList(data);
    }

    const addRow = () => {
        let newField = { name: '', dietaryRequirements: ''};
        setGuestList([...guestList, newField])
    }

    const removeRow = (index) => {
        let data = [...guestList];
        data.splice(index, 1);
        setGuestList(data);
    }



    // DATABASE FUNCTIONS

    const { 
        login, 
        rsvp,
        getInfo,
        updateStayDetail,
        updateFlightDetail,
        signout
    } = bindActionCreators(actions, useDispatch());


    useEffect(() => {

        if (!!user.authenticated) {

            setSubSpinnerSwitch(true);

            getInfo({}, (success, payload) => {


                if (success) {
    
                    setInfoSectionOutline(payload.infoSection);
                    setEventScheduleOutline(payload.eventSchedule);
                    setRSVPDetails(payload.rsvpSection);
    
                }
    
                setSubSpinnerSwitch(false);
    
            })
        }
       
        // ~~~~~~~~~ DISABLE DEPENDENCY REQS ~~~~~~~~~ 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // Initial Password uses hello@rachelanddylan.com

    const sendPassword = () => {

        setSpinnerSwitch(true);
        setSubSpinnerSwitch(true);

        const formArray = {
            email: 'hello@rachelanddylan.com',
            password
        }

        const helperArray = {
            email: setGeneralHelper,
            password: setPasswordHelper
        }

        const resetArray = {
            email: {
                shouldReset: false,
            },
            password: {
                shouldReset: true,
                setterFunction: resetPassword,
                setterValue: ''
            }
        }

        database.sendForm(
            login,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validLogin, 
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {

                if (success) {
            
                    setInfoSectionOutline(payload.eventDetails.infoSection);
                    setEventScheduleOutline(payload.eventDetails.eventSchedule);
                    setRSVPDetails(payload.eventDetails.rsvpSection);
            
                }

                setSpinnerSwitch(false);
                setSubSpinnerSwitch(false);

                
            });

    }



    const sendRSVP = () => {

        setSubSpinnerSwitch(true);

        let pass = passcode === '' ? 1234 : passcode;
        let af = arrivingFrom === '' ? 'Australia' : arrivingFrom;
        let accomdets = accomodationDetails === '' ? 'No accomodation details provided' : accomodationDetails;
        

        let gl = guestList.map((val) => {
            return {
                name: val.name === '' ? 'No name' : val.name,
                dietaryRequirements: val.dietaryRequirements === '' ? 'No dietary requirements' : val.dietaryRequirements
            }
        });

        const formArray = {
            email: attending ? email : `notattending${Math.floor(Math.random() * 1000000000)}@gmail.com`,
            mobile: attending ? mobile : '000000000',
            guestList: gl,
            passcode: attending ? pass : 'N/A',
            arrivingFrom: attending ? af : 'N/A',    
            accomodationRequired: accomodationRequired,
            arrivingAtAirport: arrivingAtAirport,
            accomodationDetails: attending ? accomdets : 'N/A',
            attending
        }

        const helperArray = {
            email: setEmailHelper,
            mobile: setMobileHelper,
            guestList: setGuestListHelper,
            passcode: setPasscodeHelper,
            arrivingFrom: setArrivingFromHelper,    
            accomodationRequired: setAccomodationRequiredHelper,
            arrivingAtAirport: setArrivingAtAirportHelper,
            accomodationDetails: setAccomodationDetailsHelper,
            attending: setAttendingHelper,
        }

        const resetArray = {
            email: {
                shouldReset: true,
                setterFunction: resetEmail,
                setterValue: ''
            },
            mobile: {
                shouldReset: true,
                setterFunction: resetMobile,
                setterValue: ''
            },
            guestList: {
                shouldReset: true,
                setterFunction: setGuestList,
                setterValue: [{ name: '', dietaryRequirements: '' }]
            },
            passcode: {
                shouldReset: true,
                setterFunction: resetPasscode,
                setterValue: ''
            },
            arrivingFrom: {
                shouldReset: true,
                setterFunction: resetArrivingFrom,
                setterValue: 'Australia'
            },
            accomodationRequired: {
                shouldReset: true,
                setterFunction: setAccomodationRequired,
                setterValue: true
            },
            arrivingAtAirport: {
                shouldReset: true,
                setterFunction: setArrivingAtAirport,
                setterValue: true
            },
            accomodationDetails: {
                shouldReset: true,
                setterFunction: resetAccomodationDetails,
                setterValue: true
            },
            attending: {
                shouldReset: true,
                setterFunction: setAttending,
                setterValue: true
            }
        }


        database.sendForm(
            rsvp,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validRSVP, 
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {

                if (success) {
    
                    setInfoSectionOutline(payload.infoSection);
                    setEventScheduleOutline(payload.eventSchedule);
                    setRSVPDetails(payload.rsvpSection);
                  
                }
    
                setSubSpinnerSwitch(false);

            });
    }


    const updateStay = () => {

        setSubSpinnerSwitch(true);

        const formArray = {
            willArriveByFirstEvent,
            willStayForWholeWedding,
            wouldLikeToStayBeyondWedding,
            alternativeAccomodationName
        }


        const helperArray = {
            willArriveByFirstEvent: setGeneralHelper,
            willStayForWholeWedding: setGeneralHelper,
            wouldLikeToStayBeyondWedding: setGeneralHelper,
            alternativeAccomodationName: setGeneralHelper
        }

        const resetArray = {
            willArriveByFirstEvent: {
                shouldReset: true,
                setterFunction: setWillArriveByFirstEvent,
                setterValue: true
            },
            willStayForWholeWedding: {
                shouldReset: true,
                setterFunction: setWillStayForWholeWedding,
                setterValue: true
            },
            wouldLikeToStayBeyondWedding: {
                shouldReset: true,
                setterFunction: setWouldLikeToStayBeyondWedding,
                setterValue: true
            },
            alternativeAccomodationName: {
                shouldReset: true,
                setterFunction: setAlternativeAccomodationNameHelper,
                setterValue: true
            },
        }


        database.sendForm(
            updateStayDetail,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validUpdateStayDetail, 
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {

                if (success) {
    
                    setInfoSectionOutline(payload.infoSection);
                    setEventScheduleOutline(payload.eventSchedule);
                    setRSVPDetails(payload.rsvpSection);
                  
                }
    
                setSubSpinnerSwitch(false);

            });
    }


    const updateFlight = () => {

        setSubSpinnerSwitch(true);

        const formArray = {
            flightNo,
            arrivalDate,
        }


        const helperArray = {
            flightNo: setGeneralHelper,
            arrivalDate: setGeneralHelper,
        }

        const resetArray = {
            flightNo: {
                shouldReset: true,
                setterFunction: resetFlightNo,
                setterValue: true
            },
            arrivalDate: {
                shouldReset: true,
                setterFunction: resetArrivalDate,
                setterValue: true
            },
        }


        database.sendForm(
            updateFlightDetail,
            formArray, 
            helperArray,
            resetArray,
            evaluate.validUpdateFlightDetail, 
            setSpinnerSwitch,
            setGeneralHelper,
            (success, payload) => {

                if (success) {
    
                    setInfoSectionOutline(payload.infoSection);
                    setEventScheduleOutline(payload.eventSchedule);
                    setRSVPDetails(payload.rsvpSection);
                  
                }
    
                setSubSpinnerSwitch(false);

            });
    }


    return (
        <>
        
            <elements.PageContainer 
                pageReady={true}
                pageTitle={defaults.tabbar.GENERAL}
                sticky={false}
                cover={'#FFF3FB'}
                menu={'access'}
            >
                   
                    <BackgroundImage>
                        <NextSectionTop>
                            <QuickDetailTitleNextRed>Please see updated event timings today | Last updated 10am, 27 Dec 2024</QuickDetailTitleNextRed>
                        </NextSectionTop>
                        <BackgroundImageInner>

                            {!!loggedIn && (   
                                <TopName>
                                    {user.name !== 'Rachel & Dylan' && user.name}
                                    <icons.CancelOutlinedStyledWhite onClick={signout}/>
                                </TopName>
                            )}
        
                            <elements.CallToAction />

                            { !loggedIn && (

                                <LogInDetail>

                                { !spinnerSwitch ? (
                                    <AnotherOption>
                                        <inputs.PasswordToEnter 
                                            title={`Password`}
                                            placeholder={`Enter password`}
                                            ready={!spinnerSwitch}
                                            type={`password`}
                                            bind={bindPassword} 
                                            value={password}
                                            additionalStyling={''}
                                            autoFocusOnMount={true}
                                            symbolRight={null}
                                            errorMessage={passwordHelper}
                                            tabIndex={1}
                                        />
                                        <ButtonSmall type={'edit'} onClick={sendPassword} backgroundCol={'#000000'}>
                                            <icons.ArrowForwardStyled />
                                        </ButtonSmall>
                                    </AnotherOption>


                                ) : (
                                    <AnotherGoAtIt>
                                        <materials.Spinner color='#a9a9a9' size='small'/>
                                    </AnotherGoAtIt>
                                ) }

                                </LogInDetail>


                            ) }

                            {
                                !!loggedIn && (
                                    <>
                                        
                                        <ButtonSmall type={'other'} onClick={() => {}} backgroundCol={'#000000'}>
                                            <icons.ArrowDownRoundedStyled />
                                        </ButtonSmall>
                                      
                                    </>
                                )
                            }

                        
                        </BackgroundImageInner>
                    </BackgroundImage>
            


                    
                    {
                        !!loggedIn && (

                            <>

                                <NextSection>
                                    <NextSectionBlocker>
                                            { !!subSpinnerSwitch ? (
                                                <AnotherGoAtIt>
                                                    <materials.Spinner color='#a9a9a9' size='small'/>
                                                </AnotherGoAtIt>
                                            ) : (
                                                !!infoSectionOutline ? (
                                                    <>
                                                        <QuickDetailTitle>{infoSectionOutline.title}</QuickDetailTitle>
                                                        <QuickDetailSubTitle>{infoSectionOutline.subtitle}</QuickDetailSubTitle>
                                                        
                                                        <InfoTable>
                                                           

                                                            {infoSectionOutline.details.map((note, index) => {
                                                              
                                                                return (
                                                                    <RowInfo key={index}>
                                                                        <ColumnInfo>{note.heading}</ColumnInfo>
                                                                        <ColumnDetailInfo>{note.heading !== 'Staying in Goa' ? note.description : (
                                                                            <p>The small villages of Agaçaim and Batim have limited accommodation options. We have arranged to accommodate the bridal and groom's party at Agaçaim's only hotel, <QuickA target="_blank" href='https://www.booking.com/hotel/in/brits-palace-suites-agacaim1.en-gb.html'>Brit's Palace</QuickA>.
                                                                            <br /><br />In the neighbouring villages, <QuickA target="_blank" href='https://www.booking.com/hotel/in/bambolim-beach-resort.en-gb.html'>Bambolim Beach Resort</QuickA> have availability for the rest of the wedding group. We recommend wedding guests book their accomodation as soon as possible as December in Goa is peak season. Accomodation is likely to start booking out months beforehand.
                                                                            <br /><br />Please let us know your accomodation details through the RSVP form below.</p>
                                                                        )}</ColumnDetailInfo>
                                                                    </RowInfo> 
                                                                )
                                                            })}
                                                            
                                                        </InfoTable>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Gap />
                                                        <QuickDetailSubTitle>There seems to be an issue loading the wedding FAQ table. <br /><br />Please let Rachel & Dylan know so they can fix it!</QuickDetailSubTitle>
                                                    </>
                                                )
                                            )}
                                            
                                    </NextSectionBlocker>
                            </NextSection>



                            
                            <NextSection>
                                    <QuickDetailTitleNext>Event schedule</QuickDetailTitleNext>

                                    <QuickDetailSubTitleNext>{'Friday, 27 December'}</QuickDetailSubTitleNext>
                                    { !!subSpinnerSwitch ? (
                                        <AnotherGoAtIt>
                                            <materials.Spinner color='#a9a9a9' size='small'/>
                                        </AnotherGoAtIt>
                                    ) : (
                                        !!eventScheduleOutline ? (
                                            <>
                                                {eventScheduleOutline.map((eachEventItem, index) => {
                                                    
                                                    return (
                                                        <>
                                                            {index >= 1 && (eachEventItem.eventDate !== eventScheduleOutline[index - 1].eventDate) && (
                                                                    <QuickDetailSubTitleNext key={index + '-B'}>{eachEventItem.eventDate}</QuickDetailSubTitleNext>
                                                            )}

                                                            <EachEvent key={index + '-A'} joinNext={eachEventItem.joinNext} joinPrior={eachEventItem.joinPrior}>
                                                                <EachEventBlock>
                                                                    <EventDate>{eachEventItem.eventTime}</EventDate>
                                                                    <EventElement>
                                                                        <EventTitle>{eachEventItem.eventTitle}</EventTitle>
                                                                        <EventDescriptionFirst><i>{eachEventItem.eventSubtitle}</i></EventDescriptionFirst>
                                                                        { eachEventItem.eventSubtitle !== '' && (
                                                                            <>
                                                                                <Gap />
                                                                                <EventDescription>{eachEventItem.eventSubtitleOther}</EventDescription>
                                                                                <EventDescription>{eachEventItem.eventSubtitleOtherAgain}</EventDescription>
                                                                            </>
                                                                        )}
                                                                    </EventElement>
                                                                </EachEventBlock>
                                                            </EachEvent>
                                                            
                                                            {
                                                                !eachEventItem.joinNext && (
                                                                    <Gap />
                                                                )
                                                            } 
                                                        </>
                                                    )
                                                })}
                                            </>
                                        ) : (
                                            <>
                                                <Gap />
                                                <QuickDetailSubTitle>There seems to be an issue loading the event schedule. <br /><br />Please let Rachel & Dylan know so they can fix it!</QuickDetailSubTitle>
                                            </>
                                        )
                                    )}

                                           

                            </NextSection>


                            <NextSectionStart>
                            
                                <NextSectionBlockerOther>
                                    
                                    { !!subSpinnerSwitch ? (
                                            <AnotherGoAtIt>
                                                <materials.Spinner color='#a9a9a9' size='small'/>
                                            </AnotherGoAtIt>
                                        ) : (
                                            rsvpDetails !== null ? (
                                                <>
                                                    <QuickDetailTitle>Thank you for RSVP'ing</QuickDetailTitle>
                                                    <QuickDetailSubTitle>Please find your details below. 
                                                    </QuickDetailSubTitle>

                                                    <EachFieldBlock>
                                                        <CenterLine />
                                                        <EachFieldMiddle>
                                                            Attending?
                                                        </EachFieldMiddle>
                                                    </EachFieldBlock>
                
                                                    <EachField>
                                                        <EachFieldTitle>
                                                            RSVP
                                                        </EachFieldTitle>

                                                        <EachFieldRow>
                                                            <ConfirmedSwitch>
                                                                {rsvpDetails.attending ? 'YES' : 'NO'}
                                                            </ConfirmedSwitch>

                                                            <FinalItem>

                    
                                                                {
                                                                    rsvpDetails.attending ? (
                                                                        <EachFieldTitleAnother fontColor={'rgba(0,0,0,0.5)'}><p>I am coming</p></EachFieldTitleAnother>
                                                                    ) : (
                                                                        <EachFieldTitleAnother fontColor={'rgba(0,0,0,0.5)'}><p>Unfortunately I can not come</p></EachFieldTitleAnother>
                                                                    )
                                                                }
                                                                
                                                            </FinalItem>
                                                        </EachFieldRow>



                                                    </EachField>
                
                                                    <EachFieldBlock>
                                                        <CenterLine />
                                                        <EachFieldMiddle>
                                                            Guest information
                                                        </EachFieldMiddle>
                                                    </EachFieldBlock>
                
                                                    <EachField>
                                                        <EachFieldTitleTop>
                                                            Guests
                                                        </EachFieldTitleTop>
                
                                                        <GroupInputs>
                                                            {
                                                                rsvpDetails.guestList.map((guest, index) => {
                                                                    return (<EachRow key={index}>
                                                                        <Counter>
                                                                            { index === 0 ? 'You' : '+'+index }
                                                                        </Counter>
                                                                        <ValueComponent
                                                                            additionalStyling={`
                                                        
                                                                                border-left:none;
                                                                                border-right:none;
                                                                                border-radius:0px;
                                                                                border-bottom-right-radius:0px;
                                                                                width:150px;

                                                                                @media (max-width: 800px) {
                                                                                    flex-direction:column;
                                                                                    width:100%;
                                                                                    border-left:1px solid #e5e5e5;
                                                                                    border-right: 1px solid #e5e5e5;
                                                                                    border-top:none;
                                                                                    border-bottom:none;
                                                                                }

                                                                            `}
                                                                        >
                                                                            {guest.name}
                                                                        </ValueComponent>
                                                                        <ValueComponent
                                                                            additionalStyling={`
                                                                
                                                                                width:100%;
                                                                                border-top-left-radius:0px;
                                                                                border-bottom-left-radius:0px;

                                                                                @media (max-width: 800px) {
                                                                                    flex-direction:column;
                                                                                    width:100%;
                                                                                }
                                                                            `}
                                                                        >
                                                                            {guest.dietaryRequirements}
                                                                        </ValueComponent>
                                                                    </EachRow>);
                                                                })
                                                            }
                
                                                        </GroupInputs>
                                                        </EachField>
                
                                                    
                                                    { rsvpDetails.attending && (
                                                        <>
                                                        <EachFieldBlock>
                                                        <CenterLine />
                                                        <EachFieldMiddle>
                                                            Travel / Accomodation
                                                        </EachFieldMiddle>
                                                    </EachFieldBlock>
                
                                                    <EachField>
                                                        <EachFieldTitle>
                                                            Arriving from
                                                        </EachFieldTitle>
                                                        
                
                                                        <ValueComponent
                                                            additionalStyling={``}
                                                        >
                                                            {rsvpDetails.arrivingFrom}
                                                        </ValueComponent>
                
                                                    </EachField>
                                                   
                                                    <EachField>
                                                        <EachFieldTitle>
                                                            Accomodation details
                                                        </EachFieldTitle>
                                                        
                
                                                        <ValueComponent
                                                            additionalStyling={``}
                                                        >
                                                            {rsvpDetails.accomodationDetails}
                                                        </ValueComponent>
                
                                                    </EachField>

                
                                                    {/* <EachField>
                                                        <EachFieldTitle>
                                                            Booking own accomodation?
                                                        </EachFieldTitle>
                                                            
                                                        <EachFieldRow>
                                                            <ConfirmedSwitch>
                                                                {rsvpDetails.accomodationRequired ? 'YES' : 'NO'}
                                                            </ConfirmedSwitch>

                                                            <FinalItem>

                    
                                                                {
                                                                    rsvpDetails.accomodationRequired ? (
                                                                        <EachFieldTitleAnother fontColor={'rgba(0,0,0,0.5)'}><p>I live locally or plan to book my own accomodation at either <QuickA target="_blank"  href='https://www.booking.com/hotel/in/bambolim-beach-resort.en-gb.html'>Bambolim Beach Resort</QuickA> or <QuickA target="_blank"  href='https://www.booking.com/hotel/in/grand-hyatt-goa.en-gb.html'>Grand Hyatt</QuickA></p></EachFieldTitleAnother>
                                                                    ) : (
                                                                        <EachFieldTitleAnother fontColor={'rgba(0,0,0,0.5)'}><p>I will be staying in the provided accomodation at <QuickA target="_blank"  href='https://www.booking.com/hotel/in/brits-palace-suites-agacaim1.en-gb.html'>Brit's Palace</QuickA></p></EachFieldTitleAnother>
                                                                    )
                                                                }
                                                                
                                                            </FinalItem>
                                                        </EachFieldRow>
                                                    </EachField>
             */}
                                                    
                                                    <EachFieldBlock>
                                                        <CenterLine />
                                                        <EachFieldMiddle>
                                                            Contact details
                                                        </EachFieldMiddle>
                                                    </EachFieldBlock>
                
                
                                                    <EachField>
                                                        <EachFieldTitle>
                                                            Mobile
                                                        </EachFieldTitle>
                                                        
                                                        <ValueComponent
                                                            additionalStyling={``}
                                                        >
                                                            {rsvpDetails.mobile}
                                                        </ValueComponent>

                                                    </EachField>
                
                                                    <EachField>
                                                        <EachFieldTitle>
                                                            Email
                                                        </EachFieldTitle>
                                                        
                                                        <ValueComponent
                                                            additionalStyling={``}
                                                        >
                                                            {rsvpDetails.email}
                                                        </ValueComponent>

                                                    </EachField>  
                                                    </>
                                                    )}                                      
                
                                                    
                                                </>) : (

                                                <>
                                                    <QuickDetailTitle>Let us know you are coming</QuickDetailTitle>
                                                    <QuickDetailSubTitle>{ !!infoSectionOutline ? infoSectionOutline.subtitle : 'Please RSVP by 1 September 2024'}</QuickDetailSubTitle>
            
                                                    <EachFieldBlock>
                                                        <CenterLine />
                                                        <EachFieldMiddle>
                                                            RSVP
                                                        </EachFieldMiddle>
                                                    </EachFieldBlock>
                                                    
                                                     <EachField>
                                                        <EachFieldTitle>
                                                            Attending?
                                                        </EachFieldTitle>

                                                        <EachFieldCenter>
                    
                                                            <inputs.Switch 
                                                                active={attending} 
                                                                onClick={() => {
                                                                    setAttending(!attending)
                                                                }}
                                                            />
                    
                                                            <FinalItem>  
                    
                                                                {
                                                                    attending ? (
                                                                        <EachFieldTitleAnother fontColor={'green'}><p>I am coming</p></EachFieldTitleAnother>
                                                                    ) : (
                                                                        <EachFieldTitleAnother fontColor={'#a9a9a9'}><p>Unfortunnately I am unable to come</p></EachFieldTitleAnother>
                                                                    )
                                                                }
                                                                
                                                            </FinalItem>
                                                        </EachFieldCenter>
                                                    </EachField>


                                                    
                
                                                    <EachFieldBlock>
                                                        <CenterLine />
                                                        <EachFieldMiddle>
                                                            Guest information
                                                        </EachFieldMiddle>
                                                    </EachFieldBlock>
                
                                                    <EachField>
                                                        <EachFieldTitleTop>
                                                            Guests
                                                        </EachFieldTitleTop>
                
                                                        <GroupInputs>
                
                                                            {
                                                                guestList.map((guest, index) => {
                
                                                                    return (
                                                                        <EachRow key={index}>
                                                            
                                                                            <Counter>
                                                                            { index === 0 ? 'You' : '+'+index }
                                                                            { index !== 0 && (<RemoveAnother onClick={() => removeRow(index)}><icons.CancelOutlinedStyled /></RemoveAnother>)}
                                                                            </Counter>
                
                                                                            <InputComponent 
                                                                                title={`Name`}
                                                                                name={'name'}
                                                                                placeholder={`Name`}
                                                                                ready={!spinnerSwitch}
                                                                                type={`text`}
                                                                                value={guest.name}
                                                                                additionalStyling={`
                                                                                    background:#ffffff;
                                                                                    border-left:none;
                                                                                    border-right:none;
                                                                                    border-radius:0px;
                                                                                    border-bottom-right-radius:0px;
                                                                                    width:150px;

                                                                                    @media (max-width: 800px) {
                                                                                        flex-direction:column;
                                                                                        width:100%;
                                                                                        border-left:1px solid #e5e5e5;
                                                                                        border-right: 1px solid #e5e5e5;
                                                                                        border-top:none;
                                                                                        border-bottom:none;
                                                                                    }

                                                                                `}
                                                                                autoFocusOnMount={true}
                                                                                symbolRight={null}
                                                                                tabIndex={index * 2 - 1 }
                                                                                onChange={(event) => handleOnChange(index, event)}
                                                                            /> 
                                                                            
                                                                            <InputComponent
                                                                                title={`Dietary Requirements`}
                                                                                name={'dietaryRequirements'}
                                                                                placeholder={`Dietary Requirements`}
                                                                                ready={!spinnerSwitch}
                                                                                type={`text`}
                                                                                value={guest.dietaryRequirements}
                                                                                additionalStyling={`
                                                                                    background:#ffffff;
                                                                                    width:100%;
                                                                                    border-top-left-radius:0px;
                                                                                    border-bottom-left-radius:0px;
                                                                                `}
                                                                                autoFocusOnMount={true}
                                                                                symbolRight={null}
                                                                                tabIndex={index * 2 }
                                                                                onChange={(event) => handleOnChange(index, event)}
                                                                            /> 
                                                        
                                                                            { index !== 0 && (<RemoveMore onClick={() => removeRow(index)}><icons.CancelOutlinedStyled /></RemoveMore>)}
                
                                                                        </EachRow>
                                                                    )
                
                                                                })
                                                            }
                
                                                            <AddMore onClick={() => addRow()}><icons.AddStyledWhite /></AddMore>
                
                                                        </GroupInputs>
                                                        </EachField>
                
                                                    { 
                                                     attending && (
                                                                    <>
                                                                        <EachFieldBlock>
                                                                        <CenterLine />
                                                                        <EachFieldMiddle>
                                                                            Travel / Accomodation
                                                                        </EachFieldMiddle>
                                                                    </EachFieldBlock>

                                                                    

                            
                                                                <EachField>
                                                                    <EachFieldTitle>
                                                                        Arriving from
                                                                    </EachFieldTitle>
                                                                    
                            
                                                                    <inputs.DropDownInput 
                                                                        title={`Arriving from`}
                                                                        placeholder={`Country`}
                                                                        ready={!spinnerSwitch}
                                                                        type={`text`}
                                                                        bind={bindArrivingFrom} 
                                                                        value={arrivingFrom}
                                                                        options={['Australia', 'India', 'Other']}
                                                                        additionalStyling={`
                                                                            background:#ffffff;
                                                                            width:100%;
                                                                        `}
                                                                        autoFocusOnMount={true}
                                                                        symbolRight={null}
                                                                        errorMessage={arrivingFromHelper}
                                                                        tabIndex={guestList.length * 2 + 1}
                                                                    /> 
                            
                                                                </EachField>

                                                                <EachField>
                                                                    <EachFieldTitle>
                                                                        Accomodation details
                                                                    </EachFieldTitle>
                                                                    
                                                                    <inputs.BasicTextInput 
                                                                        title={`Accomodation Details`}
                                                                        placeholder={`Tell us your accomodation plans`}
                                                                        ready={!spinnerSwitch}
                                                                        type={`text`}
                                                                        bind={bindAccomodationDetails} 
                                                                        value={accomodationDetails}
                                                                        additionalStyling={`
                                                                            background:#ffffff;
                                                                        `}
                                                                        autoFocusOnMount={true}
                                                                        symbolRight={null}
                                                                        errorMessage={accomodationDetailsHelper}
                                                                        tabIndex={guestList.length * 2 + 3}
                                                                    /> 
                                                                </EachField>

                                                                {/* <EachField>
                                                                    <EachFieldTitle>
                                                                        Booking own accomodation?
                                                                    </EachFieldTitle>

                                                                    <EachFieldCenter>
                                
                                                                        <inputs.Switch 
                                                                            active={accomodationRequired} 
                                                                            onClick={() => {
                                                                                setAccomodationRequired(!accomodationRequired)
                                                                            }}
                                                                        />
                                
                                                                        <FinalItem>  
                                
                                                                            {
                                                                                accomodationRequired ? (
                                                                                    <EachFieldTitleAnother fontColor={'green'}><p>I do not need accomodation / I have booked accomodation at either <QuickA target="_blank"  href='https://www.booking.com/hotel/in/bambolim-beach-resort.en-gb.html'>Bambolim Beach Resort</QuickA>, <QuickA target="_blank"  href='https://www.booking.com/hotel/in/grand-hyatt-goa.en-gb.html'>Grand Hyatt</QuickA></p>, or elsewhere.</EachFieldTitleAnother>
                                                                                ) : (
                                                                                    <EachFieldTitleAnother fontColor={'#a9a9a9'}><p>I will be staying in the provided accomodation at <QuickA target="_blank"  href='https://www.booking.com/hotel/in/brits-palace-suites-agacaim1.en-gb.html'>Brit's Palace</QuickA></p></EachFieldTitleAnother>
                                                                                )
                                                                            }
                                                                            
                                                                        </FinalItem>
                                                                    </EachFieldCenter>
                                                                </EachField> */}

                                                                <Gap />

                                                                <EachFieldBlock>
                                                                    <CenterLine />
                                                                    <EachFieldMiddle>
                                                                        Contact details
                                                                    </EachFieldMiddle>
                                                                </EachFieldBlock>
                            
                            
                                                                <EachField>
                                                                    <EachFieldTitle>
                                                                        Mobile
                                                                    </EachFieldTitle>
                                                                    
                                                                    <inputs.BasicTextInput 
                                                                        title={`Email`}
                                                                        placeholder={`+00 000 000 000`}
                                                                        ready={!spinnerSwitch}
                                                                        type={`text`}
                                                                        bind={bindMobile} 
                                                                        value={mobile}
                                                                        additionalStyling={`
                                                                            background:#ffffff;
                                                                        `}
                                                                        autoFocusOnMount={true}
                                                                        symbolRight={null}
                                                                        errorMessage={mobileHelper}
                                                                        tabIndex={guestList.length * 2 + 2}
                                                                    /> 
                                                                </EachField>
                            
                                                                <EachField>
                                                                    <EachFieldTitle>
                                                                        Email
                                                                    </EachFieldTitle>
                                                                    
                                                                    <inputs.BasicTextInput 
                                                                        title={`Email`}
                                                                        placeholder={`abc@gmail.com`}
                                                                        ready={!spinnerSwitch}
                                                                        type={`text`}
                                                                        bind={bindEmail} 
                                                                        value={email}
                                                                        additionalStyling={`
                                                                            background:#ffffff;
                                                                        `}
                                                                        autoFocusOnMount={true}
                                                                        symbolRight={null}
                                                                        errorMessage={emailHelper}
                                                                        tabIndex={guestList.length * 2 + 3}
                                                                    /> 
                                                                </EachField>
                                                        </>
                                                     )
                                                    }
                    
                                                        
                
                                                    <Gap />
                
                                                    <EachFieldBlock>
                                                        <CenterLine />
                                                    </EachFieldBlock>
                
                                                    <ButtonLarger type={'edit'} onClick={sendRSVP} backgroundCol={'#00cd70;'}>
                                                        Send RSVP
                                                    </ButtonLarger>
                                                    
                                                </>


                                            )
                                        )
                                    }


                                   
                                    
                                </NextSectionBlockerOther>
                                

                            
                            </NextSectionStart>


                            <BackgroundImageEnd>
                                <BackgroundImageInnerEnd>

                                
                                        <ButtonSmall type={'other'} onClick={() => {}} backgroundCol={'#000000'}>
                                            <icons.ArrowUpRoundedStyled />
                                        </ButtonSmall>
                                 

                                </BackgroundImageInnerEnd>
                            </BackgroundImageEnd>



                            </>

                        )
                    }
                            

            </elements.PageContainer>
        </>
    )

}

export default Front;



const StepExplaination = styled.div`
    background:#f5fafb;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
     
`

const SmallText = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    font-size:13px;
    font-weight:300;
    padding-top:5px;
`

const Counter = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    flex-grow:0;
    flex-shrink:0;
    height:51px;
    width:51px;
    background:#e5e5e5;
    color:#090909;
    border-top-left-radius:5px;
    border-bottom-left-radius:5px;

    @media (max-width: 800px) {
        width:100%;
        justify-content:space-between;
        padding:20px;
        border-bottom-left-radius:0px;
        border-top-right-radius:5px;
    }
`

const QuickA = styled.a`
    color:blue;
    background:#e5e5e5;
    border-radius:5px;
    text-decoration:none;

`

const ConfirmedSwitch = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    padding:5px 20px;
    background: #000;
    border-radius:25px;
    color:#ffffff;
    font-size:14px;
    font-weight:bold;
    opacity:0.5;
`

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10000;
`

const AddMore = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:40px;
    height:40px;
    background: #00cd70;
    border-radius:50px;
    opacity:0.5;
    transition: 0.5s all;
    cursor:pointer;

    &:hover {
        opacity:1.0;
    }
`


const AddMoreNew = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:40px;
    background: #00cd70;
    border-radius:20px;
    transition: 0.2s all;
    cursor:pointer;
    padding: 0px 20px;
    color:#ffffff;
    scale:1.0;

    &:hover {
        scale:1.1;
    }

    &:active {
        scale:0.9;
    }
`


const RemoveAnother = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:30px;
    height:30px;
    background: transparent;
    border-radius:50px;
    opacity:0.5;
    transition: 0.5s all;
    cursor:pointer;
    margin-left:10px;
    flex-grow:0;
    flex-shrink:0;

    &:hover {
        opacity:1.0;
    }

    @media (min-width: 800px) {
       display:none;
    }

    
`

const RemoveMore = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:30px;
    height:30px;
    background: transparent;
    border-radius:50px;
    opacity:0.5;
    transition: 0.5s all;
    cursor:pointer;
    margin-left:10px;
    flex-grow:0;
    flex-shrink:0;

    &:hover {
        opacity:1.0;
    }

    @media (max-width: 800px) {
       display:none;
    }

    
`

const FinalItem = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    padding:30px;
    text-align:center;

`


const EachFieldBlock = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    padding-bottom:20px;
`


const EachField = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    padding-bottom:20px;

    @media (max-width: 800px) {
        flex-direction:column;
        justify-content:flex-start;
        align-items:flex-start;
    }
`


const EachFieldCenter = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    height:150px;

    @media (max-width: 800px) {
        flex-direction:column;
        justify-content:center;
        align-items:center;
        text-align:center;
    }
`

const EachFieldCol = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    padding-bottom:20px;
`


const EachFieldRow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
`

const EachFieldBold = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    padding-bottom:40px;
    font-weight:bold;
    text-align:center;
`



const EachFieldTitle = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    flex-shrink:0;
    flex-grow:0;
    width:150px;
    padding-right:20px;
`


const EachFieldTitleLong = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:80%;
    padding-right:50px;
    font-size:14px;
`

const EachFieldTitleTop = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    width:100%;
    flex-shrink:0;
    flex-grow:0;
    width:140px;
    padding-right:20px;
`


const EachFieldTitleAnother = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    padding-right:20px;
    text-align:left;
    color: ${props => !!props.fontColor ? props.fontColor : '#a9a9a9'};

    @media (max-width: 800px) {
        padding-top:10px;
        display:block;
        flex-grow:0;
        flex-shrink:0;
    }
`



const EachFieldTitleAnotherLeft = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:100%;
    flex-shrink:0;
    flex-grow:0;
    padding-right:20px;
    padding-left:30px;
    color: ${props => !!props.fontColor ? props.fontColor : '#a9a9a9'};
`


const EachFieldMiddle = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    flex-shrink:0;
    flex-grow:0;
    width:220px;
    color:#a9a9a9;
    background:#ffffff;
    padding: 00px 20px;
    z-index:1000;
`


const ValueComponent = styled.div`
    width:100%;
    height:100%;
    border:1px solid #e5e5e5;
    outline:none;
    border-radius:5px;
    padding:15px 15px;
    font-size:16px;
    z-index:1010;
    background:#f9f9f9;
    color:#a9a9a9;

    ${props => props.additionalStyling};
`


const InputComponent = styled.input`
    width:100%;
    height:100%;
    border:1px solid #e5e5e5;
    outline:none;
    border-radius:5px;
    padding:15px 15px;
    font-size:16px;
    z-index:1010;
    background:none;

    ${props => props.additionalStyling};
    ${props => props.errorFormatting ? `
        color:#FF7F7F;
    ` : null}
`


const EachFieldMiddleSmall = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    flex-shrink:0;
    flex-grow:0;
    color:#a9a9a9;
    background:#ffffff;
    z-index:1000;
    font-size:12px;
    margin-left:30px;
    width:100px;
`

const CenterLine = styled.div`
    position:absolute;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    border-bottom:1px dashed #a9a9a9;
    z-index:500;
`


const GroupInputs = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:100%;
    padding-bottom:20px;
`



const EachRow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    width:100%;
    padding-bottom:10px;

    @media (max-width: 800px) {
        flex-direction:column;
    }


`

const AnotherSection = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    height:500px;
    width:100%;
`

const NextSection = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:100%;
    z-index:100;
`


const NextSectionTop = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:100%;
    z-index:100;
    position:absolute;
    top:0;
`

const NextSectionStart = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:100%;
    z-index:100;
    padding-top:50px;
    padding-bottom:100px;
`

const EachEvent = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:80vw;
    background: #000000;
    transition: all 0.2s;
    border-radius:30px;
    overflow:hidden;

    ${props => props.joinNext && (`
        border-bottom-left-radius:0px; 
        border-bottom-right-radius:0px; 
    `)}


    ${props => props.joinPrior && (`
        border-top-left-radius:0px; 
        border-top-right-radius:0px; 
    `)}

`

const Gap = styled.div`
    height:20px;
`


const EachEventBlock = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:100%;
    height:100%;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(10px);
    padding:20px;
`







const EventDate = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    flex-grow:0;
    flex-shrink:0;
    width:100px;
    color:#ffffff;
    text-align:center;
    border-right:1px solid #ffffff;
    height:100%;
    margin-right:20px;
    padding-right:10px;
    padding-left:10px;
`

const EventIcon = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    flex-grow:0;
    flex-shrink:0;
    width:50px;
`

const EventElement = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    width:100%;

`

const EventTitle = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    width:100%;
    font-size:25px;
    color:#ffffff;
`

const EventDescriptionFirst = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    width:100%;
    font-size:15px;
    color:#ffffff;
`


const EventDescription = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    width:100%;
    font-size:15px;
    color:#ffffff;

`

const EventDescriptionOther = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:flex-start;
    align-content:flex-start;
    width:100%;
    font-size:15px;
    color:#ffffff;
    padding-top:10px;

`




const NextSectionBlocker = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:80vw;
    padding: 40px 40px;
    background: #ffffff;
    border-radius:30px;
    position:relative;
    top:-30px;
    transition: all 0.3s;
    padding-bottom:20px;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
`



const NextSectionBlockerOther = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:80vw;
    padding: 40px 40px;
    background: #ffffff;
    border-radius:30px;
    position:relative;
    transition: all 0.3s;
    margin-top:50px;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
`

const NextSectionBlockerOtherMiddleOuter = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    padding:30px 0px;
    padding-top:0px;
    width:100%;
    
`

const NextSectionBlockerOtherMiddle = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:100%;
    padding: 30px 30px;
    background: rgba(183, 11, 157,0.2);
    border-radius:10px;
    position:relative;
    transition: all 0.3s;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 0px 10px 0px, rgba(0, 0, 0, 0.12) 0px 0px 10px 0px;
    padding-bottom:30px;
    margin-bottom:30px;
`


const NextSectionBlockerOtherMiddleFinal = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:100%;
    padding: 30px 30px;
    background: #e5e5e5;
    border-radius:10px;
    position:relative;
    transition: all 0.3s;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 0px 10px 0px, rgba(0, 0, 0, 0.12) 0px 0px 10px 0px;
    padding-bottom:30px;
    margin-bottom:30px;
`


const Blocker = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:70vw;
    padding: 40px 40px;
    background: #ffffff;
    border-radius:30px;
    transition: all 0.3s;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;
`



const SectionNextShadow = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
    padding-bottom:50px;
    padding-top: 50px;
    min-width:500px;
    padding-left:${props => props.addSidePadding ? 50 : 0 }px;
    padding-right:${props => props.addSidePadding ? 50 : 0 }px;
    ${props => {
        if (props.shadow === 'below') {
            return (`box-shadow: rgba(249, 249, 249, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;`)
        } else if (props.shadow === 'above') {
            return (`box-shadow: rgba(249, 249, 249, 0.12) 0px -9px 14px 0px, rgba(0, 0, 0, 0.12) 0px -5px 6px 0px;`)
        }
    }}
    
`

const LogInDetail = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:230px;
    padding:10px;
    background:rgba(255,255,255,0.9);
    border-radius:30px;
    flex-grow:0;
    flex-shrink:0;
`

  
const QuickDetailTitle = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    color: #000000;
    font-size:30px;
    padding-bottom:30px;
    text-align:center;
`

const QuickDetailTitleNext = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    color: #000000;
    font-size:30px;
    padding-bottom:30px;
    text-align:center;
    padding-top:60px;
`


const QuickDetailTitleNextRed = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    color: #ffffff;
    font-size:20px;
    text-align:center;
    width:100%;
    background:	#EE4B2B;
    padding:10px;
`

const QuickDetailSubTitleNext = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    color: #090909;
    font-size:18px;
    padding-bottom:10px;
    width:75vw;
    padding-left:20px;

`

const QuickDetailSubTitleNew = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    color: #a9a9a9;
    font-size:18px;
    padding-bottom:10px;
    width:75vw;
    text-align:center;
`




const QuickDetailSubTitle = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    color: #000000;
    font-size:20px;
    padding-bottom:30px;
    text-align:center;
`
const InfoTable = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    align-content:center;
    color: #000000;
`

  
  

const RowInfo = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    color: #000000;
    width:100%;
    padding:20px 0px;
    border-top:1px dashed #F0BCE3;

    @media (max-width: 800px) {
        flex-direction:column;
        width:100%;
        justify-content:flex-start;
        align-items:flex-start;
        align-content:center;
    }

`


const ColumnInfo = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    flex-grow:0;
    flex-shrink:0;
    width:150px;
    color: #000000;
    font-weight:bold;
    padding-top:5px;
`
const ColumnDetailInfo = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    width:100%;
    color: #000000;
    padding-top:5px;
`

const InternalColumnDetail = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    align-content:flex-start;
    width:100%;
    color: #000000;
`

const InternalColumnDetailElement = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    align-content:flex-start;
    width:100%;
    color: #000000;
    padding-top:5px;
`


const TopName = styled.div`
    position: absolute;
    top:20px;
    right:20px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    color: #090909;
    background: #ffffff;
    border-radius:20px;
    padding:5px;
    opacity:0.6;
    transition:all 0.5s;
    cursor:pointer;
    scale:1.0;

    &:hover {
        opacity:1.0;
        scale:1.1;
    }

    &:active {
        scale:0.9;
    }
`

const BackgroundImage = styled.div`
    background-image: url(${assets.First});
    background-repeat: no-repeat;
    background-size: 190%;
    background-position: 60% 25%;
    width:100%;
    height: 100vh;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    flex-grow:0;
    flex-shrink:0;
    position:relative;
    justify-content:center;
    align-items:center;
    align-content:center;
    z-index:5;
    box-shadow: rgba(60, 66, 87, 0.06) 0px 7px 5px 0px, rgba(0, 0, 0, 0.06) 0px 3px 5px 0px;

    @media (max-width: 800px) {
        background-size: 500%;
        background-position: 55% 25%;
    }
`


const BackgroundImageInner = styled.div`
    width:100%;
    height: 100vh;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    flex-grow:0;
    flex-shrink:0;
    position:relative;
    justify-content:center;
    align-items:center;
    align-content:center;
    background: rgba(0,0,0,0.5);
    padding: 40px 0px;
    padding-top:250px;
    padding-bottom:100px;
`



const BackgroundImageEnd = styled.div`
    background-image: url(${assets.First});
    background-repeat: no-repeat;
    background-size: 190%;
    background-position: 60% 25%;
    width:100%;
    height: 200px;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    flex-grow:0;
    flex-shrink:0;
    position:relative;
    justify-content:center;
    align-items:center;
    align-content:center;
    z-index:5;
    box-shadow: rgba(60, 66, 87, 0.06) 0px -7px 5px 0px, rgba(0, 0, 0, 0.06) 0px -3px 5px 0px;

    
`


const BackgroundImageInnerEnd = styled.div`
    width:100%;
    height: 200px;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    flex-grow:0;
    flex-shrink:0;
    position:relative;
    justify-content:center;
    align-items:center;
    align-content:center;
    background: rgba(0,0,0,0.5);
`

const AnotherOption = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    align-content:center;
    width:100%;
`




const SectionNext = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    align-content:center;
    box-sizing:border-box;
`


const BigTitleSub = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
    flex-wrap: nowrap;
    padding:0;
    margin:0;
    font-family: newFontFaceLight, Arial, sans-serif;
    color:#f9f9f9;
`


const LogoName = styled.img`
    display:flex;
    flex-direction:row;
    object-fill:contain;
    box-sizing:border-box;
    height:60px;
    padding:0;
    margin:0;
    margin-top:10px;
    padding-right:20px;

`

const AnotherGoAtIt = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    align-content:center;
    padding:12px;
    padding-bottom:22px;
`

const FormTitles = styled.span`
    font-size:14px;
    font-weight:500;
    padding-bottom:10px;
    width:100%;
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing:border-box;

`

const FieldTitle = styled.span`
    flex-grow:1;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    text-align:left;
`


const ButtonClosure = styled.button`
    color: #ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 15px 20px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    transition: all 0.5s;
    background: #de5233;
    border-radius:5px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 0.4;
    margin-right:10px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
        opacity: 0.9;
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
        opacity: 1.0;
    }


`


const ButtonSmall = styled.button`
    color: #000000;
    box-sizing:border-box;
    font-size:15px;
    padding: 5px;
    height:30px;
    width:30px;
    outline:0;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    transition: all 0.1s;
    background: ${props => !props.backgroundCol ? '#e5e5e5' : props.backgroundCol};
    border-radius:50px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 1.0;

    ${props => props.type === 'edit' ? `
        cursor:pointer;

        &:hover {
            transition: all 0.3s;
            transform: scale(0.95);
        }

        &:active {
            transition: all 0.3s;
            transform: scale(0.9);
        }

    ` : null }


`


const ButtonLarger = styled.button`
    color: #ffffff;
    box-sizing:border-box;
    font-size:15px;
    padding: 20px 10px;
    width:275px;
    outline:0;
    cursor:pointer;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    transition: all 0.1s;
    background: ${props => !props.backgroundCol ? '#e5e5e5' : props.backgroundCol};
    border-radius:15px;
    border: 1px solid transparent;
    box-shadow: transparent 0px 7px 14px 0px, transparent 0px 3px 6px 0px;
    opacity: 1.0;
    flex-grow:0;
    flex-shrink:0;
    font-size:20px;

    &:hover {
        transition: all 0.3s;
        transform: scale(0.95);
    }

    &:active {
        transition: all 0.3s;
        transform: scale(0.9);
    }


`

